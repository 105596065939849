<template>
  <DialogBase
    visible
    ref="dialog"
    icon="mdi-qr-code"
    transition="dialog-bottom-transition"
    fullscreen
    @clickClose="$close(false)"
  >
    <template v-slot:title>
      <template v-if="!isEnd">
        <v-icon left dark>mdi-briefcase-clock</v-icon>
        出勤
      </template>
      <template v-else>
        <v-icon left dark>mdi-exit-run</v-icon>
        退勤
      </template>
      <v-spacer></v-spacer>
      {{ Title }}
      {{ LastUser }}
      <v-spacer />
      <v-btn icon dark @click="$close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>

    <v-form v-model="valid" ref="form">
      <div>
        <v-progress-linear :value="(count / (interval * 1000)) * 100" />
        <QrReader v-model="data" v-if="!!count" @decode="decode" />
      </div>
    </v-form>
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";

import axios from "axios";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";

export default {
  name: "QrDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  components: {
    QrReader: () => import("@/components/QrReader.vue")
    // QrcodeStream
  },
  props: {
    isEnd: { type: Boolean, default: false }
  },
  data() {
    return {
      data: null,
      count: 0,
      interval: 30, //待機時間
      task: null,
      audio: null,
      disp: ""
    };
  },
  computed: {
    Title() {
      return `${Math.floor(this.count / 1000)} 秒待機します。`;
    },
    LastUser() {
      return this.disp;
    }
  },
  methods: {
    update() {
      const interval = 100;
      this.count = this.interval * 1000;

      if (!this.task)
        this.task = setInterval(() => {
          this.count -= interval;
          if (this.count < 1) {
            clearInterval(this.task);
            this.task = null;
            this.$close();
          }
        }, interval);
    },
    play() {
      this.audio.play();
    },
    async decode(value) {
      this.play();
      this.update();

      const split = value.split("@");
      const data = {
        loginId: split[0],
        password: split[1]
      };

      try {
        const path = !this.isEnd
          ? this.Paths.attendanceStart
          : this.Paths.attendanceEnd;
        const result = await this.$post(path, data);
        if (result) this.disp = " " + result.displaymessage;
      } catch (e) {
        this.$error(e.message);
        if (e.message === "認証トークンの有効期限切れです") {
          this.$store.commit("logout");
          this.$store.commit("user/logout");
          this.$close();
          this.$router.push({ name: "Login" });
        }
      }
    }
  },
  async created() {
    console.log(this.name + " created");

    const sound = require("@/assets/sound/beep.mp3") + "";

    const result = await axios.get(sound, {
      responseType: "blob",
      headers: {
        "Content-Type": "audio/mpeg"
      }
    });

    // const data = result.data;
    const url = URL.createObjectURL(result.data); //this.data);

    this.audio = new Audio(url);
  },
  mounted() {
    this.update();
  }
};
</script>

<style></style>
