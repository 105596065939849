<template>
  <div>
    <div class="text-center">
      <p class="text-h3">{{ $store.state.name }}</p>
      <p class="text-h3">
        {{ date.toLocaleDateString() }}{{ &nbsp; }}
        {{ date.toLocaleTimeString() }}
      </p>
    </div>

    <div class="text-center ma-10">
      <Btn
        class="mr-16"
        icon="mdi-briefcase-clock"
        color="success"
        x-big
        rounded
        @click="setAttendance(false)"
        fontSize="50"
        >出勤</Btn
      >
      <Btn
        class="ml-16"
        icon="exit-run"
        color="error"
        x-big
        rounded
        @click="setAttendance(true)"
        fontSize="50"
        >退勤</Btn
      >
    </div>

    <div class="text-center">
      <Btn
        class="mx-5"
        middle
        rounded
        icon="briefcase-edit"
        value="修正"
        fontSize="30"
        @click="click(Actions.Fix)"
      />
      <Btn
        class="mx-5"
        middle
        rounded
        icon="file-table"
        value="勤務実績"
        fontSize="30"
        @click="click(Actions.Roster)"
      />
      <Btn
        class="mx-5"
        middle
        rounded
        icon="chart-timeline"
        value="個別シフト"
        font-size="26"
        @click="click(Actions.Shift)"
      />
      <Btn
        class="mx-5"
        middle
        rounded
        icon="mdi-account-key"
        value="PWD変更"
        font-size="30"
        v-if="!isShow()"
        @click="click(Actions.Password)"
      />
      <Btn
        class="mx-5"
        middle
        rounded
        icon="mdi-car"
        value="移動登録"
        font-size="30"
        v-if="isShow()"
        @click="click(Actions.WelfareMove)"
      />
    </div>
  </div>
</template>

<script>
//mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";

//dialogs
import AttendanceDialog from "@/views/dialogs/AttendanceDialog";
import TemporaryLogin from "@/views/dialogs/TemporaryLogin.vue";
import AttendanceFixTemporaryDialog from "@/views/dialogs/AttendanceFixTemporaryDialog";
import AttendanceDetail from "@/models/AttendanceDetail";
import TemporaryAttendanceDetailDialog from "@/views/dialogs/TemporaryAttendanceDetailDialog";
import TemporaryShiftDialog from "@/views/dialogs/TemporaryShiftDialog";
import TemporaryPasswordChangeDialog from "@/views/dialogs/TemporaryPasswordChangeDialog";
import TemporaryDistanceDialog from "@/views/dialogs/TemporaryDistanceDialog";

import { BUSYO_CODE as BusyoCode } from "@/defines";

const DATE = new Date();

const Actions = {
  Eat: 0,
  Break: 1,
  MidBreak: 2,
  Fix: 3,
  Roster: 4,
  Shift: 5,
  Password: 6,
  WelfareMove: 7
};

export default {
  name: "Office",
  mixins: [Common, ShowDialogs, Api],
  data: () => ({
    date: DATE,
    targetdate: `${DATE.getFullYear()}-${DATE.getMonth() +
      1}-${DATE.getDate()}`,
    Actions
  }),
  methods: {
    isShow() {
      if (this.$store.state.shozokuCode1 === BusyoCode.WELFARE) {
        return true;
      }
      return false;
    },
    async click(action) {
      console.log(action);

      if (!this.$store.state.user.login) {
        const result = await this.$showDialog(TemporaryLogin);
        console.log(result);

        if (!result) return;
      }

      switch (action) {
        case Actions.Fix:
          await this.fix();
          break;
        case Actions.Roster:
          await this.roster();
          break;
        case Actions.Shift:
          await this.shift();
          break;
        case Actions.Password:
          await this.pwdchange();
          break;
        case Actions.WelfareMove:
          await this.setdistance();
          break;
      }
    },
    async fix() {
      await this.updateDate();
      const ts = this.date.getTime();
      const param = {
        incode: this.$store.state.incode,
        userincode: this.$store.state.user.incode,
        targetdate: this.targetdate
      };

      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);

      const resp = await this.$get(
        this.Paths.attendance,
        "temporary=" + encode
      );

      const args = new AttendanceDetail(
        resp.recordid ? resp.recordid : "",
        resp.employee ? resp.employee : this.$store.state.user.incode,
        resp.affiliation ? resp.affiliation : this.$store.state.incode,
        resp.shiftid ? resp.shiftid : "",
        resp.shiftpatternid ? resp.shiftpatternid : "",
        this.targetdate,
        ts,
        resp.start ? resp.start : "",
        resp.end ? resp.end : "",
        resp.distance ? resp.distance : "0",
        resp.inover ? resp.inover : "",
        resp.lateleave ? resp.lateleave : "",
        resp.amount ? resp.amount : "",
        resp.breakflg ? resp.breakflg : "",
        resp.daycount ? resp.daycount : "",
        resp.nightcount ? resp.nightcount : "",
        resp.hadmeal ? resp.hadmeal : "0",
        resp.careShu ? resp.careShu : "0",
        resp.careKbn ? resp.careKbn : "0",
        resp.timeKbn ? resp.timeKbn : "0"
      );
      await this.$showDialog(AttendanceFixTemporaryDialog, { args });
    },
    async roster() {
      await this.updateDate();

      const param = {
        incode: this.$store.state.incode,
        userincode: this.$store.state.user.incode,
        targetdate: this.targetdate
      };

      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);
      try {
        const item = await this.$get(
          this.Paths.attendance,
          "tmpattendance=" + encode
        );
        await this.$showDialog(TemporaryAttendanceDetailDialog, { args: item });
      } catch (e) {
        console.log(e);
        this.$error(e.message);
        this.$store.commit("user/logout");
      }
    },
    async shift() {
      const date = this.$getCrossServiceDate();

      const param = {
        userincode: this.$store.state.user.incode,
        targetmonth:
          parseInt(date.split("-")[0]) + "-" + parseInt(date.split("-")[1])
      };

      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);

      try {
        const item = await this.$get(this.Paths.shift, "tmpshift=" + encode);
        await this.$showDialog(TemporaryShiftDialog, { args: item });
      } catch (e) {
        console.log(e);
        this.$error(e.message);
        this.$store.commit("user/logout");
      }
    },
    async pwdchange() {
      try {
        const param = {
          userincode: this.$store.state.user.incode,
          usercode: this.$store.state.user.code
        };
        await this.$showDialog(TemporaryPasswordChangeDialog, { args: param });
      } catch (e) {
        console.log(e);
        this.$error(e.message);
        this.$store.commit("user/logout");
      }
    },
    async setdistance() {
      try {
        const queryparam = {
          userincode: this.$store.state.user.incode,
          targetdate: this.targetdate
        };
        const encode = encodeURI(JSON.stringify(queryparam));
        const records = await this.$get(
          this.Paths.tmpDistance,
          "query=" + encode
        );

        const param = {
          userincode: this.$store.state.user.incode,
          usercode: this.$store.state.user.code,
          dataheaders: records.headers,
          databodies: records.body
        };
        await this.$showDialog(TemporaryDistanceDialog, { args: param });
      } catch (e) {
        console.log(e);
        this.$error(e.message);
        this.$store.commit("user/logout");
      }
    },
    async setAttendance(isEnd = false) {
      const result = await this.$showDialog(AttendanceDialog, { isEnd });
      console.log(result);
    },
    async updateDate() {
      this.date = new Date();
      this.targetdate = `${this.date.getFullYear()}-${this.date.getMonth() +
        1}-${this.date.getDate()}`;
    }
  },
  mounted() {
    setInterval(() => {
      this.date = new Date();
    }, 500);
  }
};
</script>

<style></style>
